@import './css/vars.css';
@import '/node_modules/aos/dist/aos.css';
@import './css/aos.css';
@import './css/lenis.css';
@import './css/menu.css';
@import './css/slider-home.css';

table {
    width: 100%;
    background: #FFFFFF;
    
    tr:nth-child(odd) {
        background-color: var(--gray-light);
    }

    td {
        padding: 0.75rem;
    }
}

.text-grow-up-meters {
    color: var(--brand);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.75rem;

    .text-grow-up-meters__number {
        font-size: 2.375rem;
        font-weight: 400;
        line-height: 1;
    }
    
    .text-grow-up-meters__meters {
        font-size: 1rem;
    }
}


.section_career-content ul,
.product-about__left ul {
    list-style: none;

    li {
        position: relative;
    }

    li:before {
        content: "";
        display: block;
        position: absolute;
        left: -1rem;
        top: .6rem;
        width: 7px;
        height: 7px;
        background-color: var(--dim-gray);
    }
}

.product-about__left ul li:before {
    background-color: var(--brand);
}

.loader__container {
    display: flex;
}

.w-editor-body .loader__container {
    display: none !important;
}

.loader__bg {
    animation: elevator 2s infinite;
}

@keyframes elevator {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-100%);
    }
    51% {
        transform: translateY(100%);
    }
}


.card.aspect-vertical-rect {
    transition: all .8s var(--ease-base);


    .card__img {
        transform: translateY(10%);
        transition: all 1.2s var(--ease-base);
    }
    
    .card__icon-cont {
        transform: rotate(0deg);
        transition: all 1s var(--ease-base);
    }

    &:hover, &:active {
        background-color: var(--gray-light);

        .card__icon-cont {
            transform: rotate(-45deg);
            background-color: var(--brand);
        }

        .card__img {
            transform: translateY(0);
        }
    }
}

.card-product,
.card-accessories {

    h1, h2, h3, h4 {
        transition: all .8s var(--ease-base);
    }

    .arrow-icon-cont {
        transform: rotate(0deg);
        transition: all .8s var(--ease-base);
    }

    &:hover,
    &:active {
        h1, h2, h3, h4 {
            color: var(--brand);
        }

        .arrow-icon-cont {
            transform: rotate(-45deg);
            background-color: var(--brand);
        }
    }
}


.logo-light,
.logo-dark {
    transition: opacity 400ms 1800ms;
}

.lng-selector {
    transition: color 400ms 1500ms;
}

.is-light-nav .navbar {
    color: white;
}

.is-light-nav .logo-light {
    opacity: 1;
}

.is-light-nav .logo-dark {
    opacity: 0;
}

.is-semilight-nav .navbar {
    color: white;
}

@media screen and (max-width: 768px) {
    .is-semilight-nav .logo-light {
        opacity: 1;
    }

    .is-semilight-nav .logo-dark {
        opacity: 0;
    }
}

.menuIsOpen {
    .logo-light,
    .logo-dark {
        transition: opacity 400ms 800ms;
    }

    .lng-selector {
        transition: color 400ms 500ms;
        color: #FFFFFF;
    }

    .logo-light {
        opacity: 0 !important;
    }

    .logo-dark {
        opacity: 1 !important;
    }
}


.hero-line-svg path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
}

.dot__aurea.is-2 {
    animation: spark 3s infinite;
    animation-timing-function: ease-in-out;
}

.dot__aurea.is-3 {
    animation: spark 3s 0.4s infinite;
    animation-timing-function: ease-in-out;
}

@keyframes spark {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    60% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}