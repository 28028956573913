
.section_projects-home .swiper-slide {
    display: flex;
    height: 100%;
    align-items: end;
    overflow: visible;
}

.swiper-wrapper,
.section_projects-home .slider__img-cont,
.section_projects-home .button-big.is-changing {
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.section_projects-home .swiper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

/*
.section_projects-home .swiper-slide {
  width: 25%;
}

.section_projects-home .swiper-slide-active {
  width: 33%;
}
*/

.section_projects-home .slider__img-cont {
    transition-property: height;
    transition-duration: 1s;
    height: 70%;
    width: 100%;
    transform-origin: bottom;
}

.section_projects-home .swiper-slide-active .slider__img-cont {
    height: 100%;
}

.section_projects-home .slider__panel-content {
    transition-property: all;
    transition-duration: .3s;
    transition-delay: .1s;
    opacity: 0;
    transform: translate(0, 40px);
}

.section_projects-home .swiper-slide-active .slider__panel-content {
    transition-delay: .5s;
    opacity: 1;
    transform: translate(0, 0px);
}


.section_projects-home .button-big {
    transition-duration: 0.3s;
    transition-property: all;
    transition-delay: .5s;
    opacity: 1;
    transform: translate(0, 0px);
}

.section_projects-home .button-big.is-changing {
    transition-delay: 0s;
    opacity: 0;
    transform: translate(0, 20px);

}

.section_projects-home .swiper {
    max-width: 90%;
    overflow: visible;
}