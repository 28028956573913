
.lenis.lenis-smooth {
  scroll-behavior: auto;
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}